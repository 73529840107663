import React, { useEffect, useRef, useState } from 'react';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

const AccountSearchInput =  (props) => {

    const [searchText, setSearchText] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const loadRef = useRef(false);

    useEffect(() => {
        // props.getAccountUser();
        console.log(loadRef.current)
        if (loadRef.current == false) {
            loadRef.current = true;
        } else if (loadRef.current == true) {
            props.getAccountUser();
        }
    }, [searchText, startDate, endDate])


    function storeChange(e, item) {
        if (item === 'search') {
            // console.log(`search: ${e.target.value}`);
            setSearchText(e.target.value);
            props.handleChange(e, 'search');

        } else if (item === 'startDate') {
            // console.log(`start: ${e.target.value}`);
            setStartDate(e.target.value);
            props.handleChange(e, 'startDate');

        }else if (item === 'endDate') {
            // console.log(`end: ${e.target.value}`);
            setEndDate(e.target.value);
            props.handleChange(e, 'endDate');
        }
    }

    return(
        <>  
            <div className="col-md-6 search-bar">
                <TextField
                    type="text"
                    id="adornment-search"
                    label="Keyword Search"
                    name="search"
                    placeholder="Search by name, email, phone number or account number"
                    onChange={(e) => storeChange(e, 'search')}
                    onKeyPress={props.handleKeyPressSearch}
                    // value={this.state.search}
                    // variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    // onClick={this.handleClickSearch}
                                >
                                    <i className="fas fa-search"> </i>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    fullWidth
                />
            </div>
            <div className='col-md-6 search-bar'>
                <TextField
                    id="start-date"
                    label="Start Date"
                    type="date"
                    // defaultValue="2021-01-01"
                    className="search-start-date"
                    onChange={(e) => storeChange(e, 'startDate')}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    style={{marginRight: '10px'}}
                />
                <TextField
                    id="end-date"
                    label="End Date"
                    type="date"
                    className="search-end-date"
                    onChange={(e) => storeChange(e, 'endDate')}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    inputProps={{
                        min: startDate,
                    }}
                />
            </div>
        </>
    )

}
export default AccountSearchInput;